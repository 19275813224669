/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import "document-register-element";

import "@fremtind/jkl-core/core.min.css";
import "@fremtind/jkl-button/button.min.css";
import "@fremtind/jkl-table/table.min.css";


import { initTabListener } from "@fremtind/jkl-core";

import { A11yContextProvider } from "./src/contexts/a11yContext";

initTabListener();

export const wrapRootElement = ({ element }) => {
  return <A11yContextProvider>{element}</A11yContextProvider>;
};
